import React, { Component, useEffect, useRef, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, Row } from 'reactstrap';

import { Link, useParams, Router, useNavigate } from 'react-router-dom';

import { BOOK_API_URL, BOOK_LINK_A, BOOK_LINK_I, BOOK_LINK_M, BOOK_LINK_W } from "../../../Config";
import windows from "../../images/os/windows.png"
import mac from "../../images/os/mac.png"
import android from "../../images/os/android.png"
import ios from "../../images/os/ios.webp"

const BookPurchase = () => {
    const navigate = useNavigate();
    // const { t } = useTranslation();
    const { type } = useParams();
    const accessToken = localStorage.getItem('accessToken');
    const userid = localStorage.getItem('user_id');
    const [owner, setOwner] = useState([]);
    const [autoupdate, setAutoUpdate] = useState(0);
    const [coupon, setCoupon] = useState();

    const [autorenew, setAutoRenew] = useState(0);
    const [deviceLength, setDeviceLength] = useState(0);
    // const [deviceLength, setDeviceLength] = useState(0);
    const [ownerfive, setOwnerfive] = useState([]);
    const [ownersix, setOwnersix] = useState([]);
    const [ownerseven, setOwnerseven] = useState([]);
    const [Loader, setLoader] = useState(false)
    const [showDownload, setShowDownload] = useState(false);
    const [messageModal, setmessageModal] = useState(false);
    const messageToggle = () => setmessageModal(!messageModal);

    const [downloadModal, setdownloadModal] = useState(false);
    const downloadModalToggle = () => setdownloadModal(!downloadModal);


    const [paymentModal, setPaymentModal] = useState(false);
    const paymentModalToggle = () => setPaymentModal(!paymentModal);

    const [message, setmessage] = useState(false);
    const [messageHead, setmessageHead] = useState(false);

    const [perDevice, setPerDevice] = useState(175);
    const [perGoDevice, setPerGoDevice] = useState(75);
    // const [deviceToRenew , setDevcieToRenew ] = useState([]);
    const [upgradeOption, setUpgradeOption] = useState(type);
    const [upgradeCost, setUpgradCost] = useState(0);
    const [email, setEmail] = useState(null);
    const [error, setError] = useState(false);
    const [showPay, setShowPay] = useState(false);
    const [price, setPrice] = useState(45);


    const [payPalModal, setPayPalModal] = useState(false);
    const payPalToggle = () => setPayPalModal(!payPalModal);





    const payNow = () => {
        $("#paypal-button-container").html('')

        let _price = price;
        _price = parseFloat(_price);
        // _price = 0.01;
        if (_price > 0) {
            paypal.Buttons({
                createOrder: function (data, actions) {
                    return actions.order.create({
                        purchase_units: [{
                            amount: {
                                currency_code: "USD",
                                value: _price,
                                breakdown: {
                                    item_total: {  /* Required when including the `items` array */
                                        currency_code: "USD",
                                        value: _price
                                    }
                                }
                            },
                            items: [
                                {
                                    name: "CapnoLearning E-book", /* Shows within upper-right dropdown during payment approval */
                                    description: "CapnoLearning E-book purchase", /* Item details will also be in the completed paypal.com transaction view */
                                    unit_amount: {
                                        currency_code: "USD",
                                        value: _price
                                    },
                                    quantity: 1
                                },
                            ]
                        }]
                    });
                },
                onApprove: function (data, actions) {
                    return actions.order.capture().then(function (details) {
                        //   // console.log(details)

                        completePayment(details);

                    })
                },
                onCancel: function (data) {
                    // window.location.replace("<?php echo $site_url;?>sales/CeRegister_new_back.php")
                }
            }).render('#paypal-button-container');
        }


    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value)
    }

    const completePayment = async (details) => {
        let transaction_id = details.id

        localStorage.setItem('pdfemail' , email )
        localStorage.setItem('pdftxn' , transaction_id )

        const response = await fetch(BOOK_API_URL + '/save/transactions', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email,
                transaction_id: transaction_id,
                transaction_body: JSON.stringify(details)
            }),
        })

        const data = await response.json()

        if (data.status) {
            // setmessageHead("Thank you!")
            // setmessage("Your payment for e-book was successful. <br /> <br /> Please note your <b>TRANSACTION ID</b> below. <i class='fa fa-arrow-down'></i>  <br /><b>" + transaction_id + "</b><br /><br />");
            // messageToggle();

            window.location.href = "/book/invoice"

            setShowDownload(true)




        }
        else {
            setShowDownload(false)

            setmessageHead("Error!!")
            setmessage("Some Error Occured")
            messageToggle();


        }
    }
    const setErrorMessage = (t) => {
        setError(t);
        setTimeout(() => {
            setError(false);

        }, 5000);
    }

    const validateCopoun = async () => {
        if (!email) {
            setErrorMessage("Email is required.");
            return false
        }

        if (!coupon) {
            setErrorMessage("Coupon is required.");
            return false
        }

        const response = await fetch(BOOK_API_URL + '/validate/coupon', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email,
                coupon: coupon,
            }),
        })

        const data = await response.json()
        if (data.status) {

            localStorage.setItem('pdfemail' , email )
            localStorage.setItem('pdftxn' , data.transaction_id )

            window.location.href = "/book/invoice"

            // setmessageHead("Thank you!")
            // setmessage("Your payment for e-book was successful. <br /> <br /> Please note your <b>TRANSACTION ID</b> below. <i class='fa fa-arrow-down'></i>  <br /><b>" + data.transaction_id + "</b><br /><br />");
            // messageToggle();
            setShowDownload(true)




        }
        else {
            setShowDownload(false)
            setmessageHead("Error!!")
            setmessage("Some Error Occured: <span class='text-danger'> " + data.message + "</span>")
            messageToggle();


        }
    }

    const showPayPanel = () => {
        if (!email) {
            setErrorMessage("Email is required.");
            return false
        }
        setShowPay(true);
        setTimeout(() => {
            payNow()

        }, 1000);

        setLoader(true)

        const interval = setInterval(() => {
            setLoader(false)
        }, 2000);

        return () => clearInterval(interval);

    }

    return (
        <div>

            <div className="wrp-dashbord">

                <div className="container-fluid" >

                    <div className="row">
                        <div className="col-lg-2 mt-5">
                        </div>
                        <div className="col-lg-8">

                            <div className="wrppuchasebook">

                                <div className="notification-c">
                                    <h3 className="text-center mb-3">Purchase E-Book</h3>
                                    <h2>CapnoLearning™</h2>
                                    <h1>An Introductory Guide</h1>
                                    <h5>Breathing habits, optimal respiration, and acid-base physiology</h5>
                                    <div className="peter-m-content">
                                        <p>Peter M. Litchfield, Ph.D.</p>
                                        <p>and</p>
                                        <p>Sandra Reamer, MFA, MS, CBBA, CBBP, CSOM, CBT</p>
                                    </div>
                                </div>

                                <div className="wrp-publisher-content">
                                    <h5>Publisher</h5>
                                    <h3>Breathing Science, Inc.</h3>
                                    <p>A 501(c)(3) nonprofit corporation</p>
                                    <p>April 2023</p>
                                    <p>ISBN: 979-8-218-16278-8</p>
                                    <span>©2023 Peter M. Litchfield & Sandra Reamer. All Rights Reserved</span>
                                    <h3 className="price-breathing">${price}</h3>
                                    <h4>Click here to <a href="javascript:void(0)" onClick={() => paymentModalToggle()}>make a purchase</a>.</h4>
                                    <div className="instruction-p"><span>Instructions: reading the book</span></div>
                                </div>
                                <div className="puchase-p">
                                    <p>Purchasing this book means accessing it from your computer, tablet, or cell phone. Once you make payment, you will <br></br>
                                        receive a transaction number which will permit you to download a book-access icon, which you will click on whenever <br></br>
                                        you wish to read the book. DO NOT LOSE THIS NUMBER.<br></br>
                                        You may download this book-access icon on up to three different devices.<br></br>
                                        Email: education@bp.edu</p>
                                </div>




                            </div>

                        </div>
                        <div className="col-lg-2 mt-5">
                        </div>

                    </div>

                </div>
            </div>


            <Modal isOpen={messageModal} toggle={messageToggle} className="connect-box" centered={true}>
                <ModalHeader toggle={messageToggle}><span className="ml-1 roititle font-weight-bold">{messageHead}</span></ModalHeader>
                <ModalBody>
                    <div dangerouslySetInnerHTML={{ __html: message }}>

                    </div>
                    {
                        showDownload &&
                        <a href='javascript:void' onClick={downloadModalToggle}>Download PDF application <i class='fa fa-download'></i></a>
                    }

                </ModalBody>

            </Modal>

            <Modal isOpen={downloadModal} toggle={downloadModalToggle} className="connect-box" centered={true}>
                <ModalHeader toggle={downloadModalToggle}><span className="ml-1 roititle font-weight-bold">Choose your Platform</span></ModalHeader>
                <ModalBody>
                    <div className="d-flex justify-content-around">
                        <div className="bigIcon">
                            <a href={BOOK_LINK_W}><img src={windows} className="osicon" />
                                <p>Windows</p></a>
                        </div>
                        <div className="bigIcon">
                            <a href={BOOK_LINK_M}><img src={mac} className="osicon" />
                                <p>Mac</p></a>

                        </div>
                        {/* <div className="bigIcon">
                            <a href={BOOK_LINK_A}>
                            <img src={android} className="osicon" />
                            <p >Android</p></a>

                            </div>  
                            <div className="bigIcon">
                            <a href={BOOK_LINK_I}>
                            <img src={ios} className="osicon" />
                            <p>iOS</p></a>

                            </div>   */}
                    </div>
                </ModalBody>

            </Modal>


            <Modal isOpen={paymentModal} toggle={paymentModalToggle} className="connect-box" centered={true}>
                <ModalHeader toggle={paymentModalToggle}><span className="ml-1 roititle font-weight-bold">Complete your payment here</span></ModalHeader>
                <ModalBody className="payment-modal">
                    <div className="container" >
                        {
                            !showPay &&
                            <>                     <div class="form-group col-sm-12">
                                <label for="email">Email address:</label>
                                <input type="email" value={email} onChange={(e) => handleEmailChange(e)} class="form-control" placeholder="Enter email address here" id="email" />
                            </div>


                                <div class="form-group col-sm-12">

                                    <label>Have a coupon code ?</label><br></br>
                                    <input type="text" value={coupon} onChange={(e) => setCoupon(e.target.value)} class="form-control" placeholder="Enter coupon code here" ></input>
                                    <p className="text-danger text-center mt-3" >{error}</p>

                                    <div className="d-flex justify-content-between">

                                        <button className="btn btn-purple mt-3 " onClick={validateCopoun}>Apply</button>
                                        <h5 className="mt-3">or</h5>
                                        <button className="btn btn-purple mt-3 " onClick={() => showPayPanel()}>Pay</button>

                                    </div>
                                </div>
                            </>
                        }
                        {
                            Loader && <div className="loderwrp"><div id="loader"></div></div>
                        }
                        {
                            showPay &&

                            <div className="yourcard">
                                  {
                            !Loader && 
                               <> <button className="btn btn-purple" onClick={() => setShowPay(false)}><i className="fa fa-arrow-left"></i> Go back</button>
                               <p>Your card will be charged ${price} for this purchase</p></>
                                  }
                                <div id="paypal-button-container" className="mt-4">

                                </div>
                            </div>
                        }

                    </div>

                </ModalBody>

            </Modal>



        </div>
    )
}

export default BookPurchase;