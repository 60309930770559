import React, { Component, useState, useEffect, useRef } from 'react';
import TCPDF from '../pdf/terms-and-conditions-capnobook.pdf'
 
const BookTermsCondition = () => {
  
    return (
        <div className="wrp-dashbord ">
           <object data={TCPDF} type="application/pdf" width="100%" height="750vh" />

        </div>
    )
}
export default BookTermsCondition;