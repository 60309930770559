import React, { useState } from "react";

const CapnolearningBrochure = ({
  checkoutsuccessToggleModal,
  setShowForm,
  show,
  setShow,
}) => {
  return (
    <div className="container">
      <div className="p_brochure">
        <div style={{ textAlign: "center" }}>
          <p
            style={{
              fontWeight: "600",
              fontSize: "21px",
              marginBottom: "2px",
            }}
          >
            PROFESSIONAL SCHOOL OF BEHAVIORAL HEALTH SCIENCES
          </p>
          <p
            style={{
              fontWeight: "600",
              fontSize: "21px",
              marginBottom: "2px",
              marginTop: "12px",
            }}
          >
            <em>Technician Certification Program</em>
          </p>
          <p
            style={{
              fontSize: "26px",
              color: "#383886",
              fontWeight: "600",
              marginBottom: "0px",
            }}
          >
            CapnoLearning
          </p>
          <p
            style={{
              fontSize: "14px",
              marginBottom: "1.5rem",
              fontWeight: "bold",
            }}
          >
            Technology, Science, Assessment, Applications
            <br />
            10 learning modules with practicum, 4 academic credits
          </p>

          <p
            style={{
              fontSize: "14px",
              margin: "0 auto 0px",
              width: "100%",
              textAlign: "start",
            }}
          >
            Statistics suggest that tens of millions of people worldwide suffer
            with the profound and misunderstood symptoms and deficits of learned
            dysfunctional breathing habits that compromise respiration. This
            compromise is primarily the result of hypocapnia, carbon dioxide
            (CO2) deficit, brought about by overbreathing. CapnoLearning brings
            together respiratory physiology, capnography technology, and
            behavioral science for learning to restore optimal respiration,
            i.e., <b>eucapnia</b>.
          </p>
          <p
            style={{
              fontSize: "14px",
              marginTop: "1rem",
              width: "100%",
              textAlign: "start",
            }}
          >
            The Professional School offers{" "}
            <b>a technician certification program in CapnoLearning</b> for
            healthcare practitioners, human service professionals, health
            educators, and performance enhancement consultants interested in
            helping clients learn optimal respiration. Certification candidates
            learn to use capnography instrumentation for identifying breathing
            habits that compromise respiration, helping clients disengage and
            manage dysfunctional breathing habits, and assisting clients in
            learning new habits that optimize respiration, i.e., restore CO2
            concentration.
          </p>
          <div style={{ padding: "1rem 0" }}>
            <p
              style={{
                fontSize: "14px",
                marginBottom: "0px",
                fontWeight: "600",
              }}
              // onClick={()=>setShowForm(true)}
            >
              Tuition: $2,400.00
            </p>
            <p
              style={{
                fontSize: "14px",
                marginBottom: "0px",
                fontWeight: "600",
              }}
              // onClick={()=>setShowForm(true)}
            >
              Deposit: $300.00
            </p>
            <p
              style={{
                fontSize: "14px",
                marginBottom: "10px",
                fontWeight: "600",
                color: "#383886",
                display: "inline",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => checkoutsuccessToggleModal()}
            >
              CLICK HERE TO REGISTER
            </p>
            <p
              style={{
                fontSize: "13px",
                marginBottom: "1rem",
                marginTop: "0px",
              }}
            >
              OR go to{" "}
              <a href="https://e-campus-registration.bp.edu/" target="_blank">
                www.e-campus.bp.edu
              </a>
            </p>
          </div>
          {!show && (
            <p
              style={{
                fontSize: "14px",
                marginBottom: "2px",
                fontWeight: "600",
                color: "#383886",
                //   display: "inline",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => setShow(!show)}
            >
              CLICK HERE TO READ MORE...
            </p>
          )}

          {show && (
            <p
              style={{
                fontSize: "14px",
                marginBottom: "1rem",
                fontWeight: "600",
                color: "#383886",
                //   display: "inline",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => setShow(!show)}
            >
              CLICK HERE TO HIDE.
            </p>
          )}
        </div>
        {show && (
          <div style={{ padding: "1rem 0 0" }}>
            <p style={{ fontSize: "14px", marginBottom: "1rem" }}>
              <span style={{ color: "#383886", fontWeight: "600" }}>
                Coursework:
              </span>{" "}
              learning modules, tests, and webinar review sessions
              <br />
              The program is divided into ten 3-hour prerecorded modules, each
              followed by a short multiple-choice test. These modules include
              science, technology, assessment protocols, and training
              applications. Five live 2-hour webinar questions & answer periods
              are scheduled on Zoom during the trimester. Candidates earn two
              hours of academic credit.
            </p>
            <p style={{ fontSize: "14px", marginBottom: "1rem" }}>
              Module 1: respiratory physiology
              <br />
              Module 2: capnography technology
              <br />
              Module 3: breathing psychology
              <br />
              Module 4: assessment tools
              <br />
              Module 5: interviewing techniques
              <br />
              Module 6: breathing testing protocols
              <br />
              Module 7: self-interventions
              <br />
              Module 8: desensitization
              <br />
              Module 9: negative practice
              <br />
              Module 10: biofeedback protocols
            </p>
            <p style={{ fontSize: "14px", marginBottom: "1rem" }}>
              <span style={{ color: "#383886", fontWeight: "600" }}>
                Practicum:
              </span>{" "}
              assessment and training requirements, evaluation session
              <br />
              Candidates are required to own or rent a CapnoTrainer and to
              complete a practicum consisting of 10 assessment and 10 training
              sessions (40 hours) with their own volunteer subjects. These
              sessions are documented by uploading data, session notes, and
              forms to the Professional School CapnoLearning database for
              evaluation. Each candidate is required to meet for a 2- hour Zoom
              evaluation session with a Professional School faculty member to
              discuss their practicum work. Candidates earn two hours of
              academic credit for successful completion of the practicum work.
            </p>
            <p style={{ fontSize: "14px", marginBottom: "1rem" }}>
              <span style={{ color: "#383886", fontWeight: "600" }}>
                Scheduling:
              </span>{" "}
              one trimester, four academic units
              <br />
              Candidates may complete the modules at their own pace, but are
              expected to complete the program within one trimester. Multiple-
              choice tests on the School e-campus website may be taken once a
              module has been completed.
            </p>
            <p style={{ fontSize: "14px", marginBottom: "1rem" }}>
              Questions & answer sessions are scheduled on specific dates during
              the trimester and are recorded for easy review by candidates who
              cannot otherwise attend. Practicum interviews are scheduled during
              the last four weeks of the trimester; candidates sign-up for their
              interviews on the e-campus Professional School calendar.
            </p>

            <p style={{ fontSize: "14px", marginBottom: "1rem" }}>
              <span style={{ color: "#383886", fontWeight: "600" }}>
                Admission:
              </span>{" "}
              resume and documents
              <br />
              Resume: include education and experience in healthcare, human
              service, or performance training
              <br />
              Documents: include higher education transcripts and copies of
              certifications and licenses
            </p>
            <p style={{ fontSize: "14px", marginBottom: "1rem" }}>
              <span style={{ color: "#383886", fontWeight: "600" }}>
                Registration:
              </span>{" "}
              website and email
              <br />
              Go to:{" "}
              <a href="https://e-campus-registration.bp.edu/" target="_blank">
                www.e-campus.bp.edu
              </a>
              . Email: <a href="mailto:education@bp.edu">education@bp.edu</a>
              <br />
            </p>

            <div
              style={{
                textAlign: "center",
                background: "#f9ebeaad",
                padding: "10px",
              }}
            >
              <p
                style={{
                  fontSize: "14px",
                  marginBottom: "0px",
                  fontWeight: "600",
                }}
                // onClick={()=>setShowForm(true)}
              >
                Tuition: coursework and practicum
                <br />
                $2,400.00
              </p>
              <p
                style={{
                  fontSize: "14px",
                  marginBottom: "0px",
                  fontWeight: "600",
                }}
                // onClick={()=>setShowForm(true)}
              >
                Deposit:
                $300.00
              </p>
              <p
                style={{
                  fontSize: "14px",
                  marginBottom: "10px",
                  fontWeight: "600",
                  color: "#383886",
                  display: "inline",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => setShowForm(true)}
              >
                CLICK HERE TO REGISTER
              </p>
              <p
                style={{
                  fontSize: "13px",
                  marginBottom: "1rem",
                  marginTop: "0px",
                }}
              >
                OR go to{" "}
                <a href="https://e-campus-registration.bp.edu/" target="_blank">
                  www.e-campus.bp.edu
                </a>
              </p>

              <p
                style={{
                  fontSize: "12px",
                  marginBottom: "2px",
                  fontWeight: "bold",
                  color: "#383886",
                }}
              >
                Behavioral Breathing Sciences
              </p>
              <p
                style={{
                  fontSize: "13.5px",
                  marginBottom: "0px",
                  fontWeight: "bold",
                }}
              >
                PROFESSIONAL SCHOOL OF BEHAVIORAL HEALTH SCIENCES
              </p>
              <p
                style={{
                  fontSize: "15px",
                  marginBottom: "0",
                }}
              >
                Professional Diploma, Certificate, and Certification programs
              </p>
              <p
                style={{
                  fontSize: "13px",
                  marginBottom: "2rem",
                  //   marginTop: "2rem",
                }}
              >
                <a href="mailto:education@bp.edu">education@bp.edu</a>{" "}
                307.633.9800
              </p>

              {show && (
                <p
                  style={{
                    fontSize: "14px",
                    marginBottom: "1rem",
                    fontWeight: "600",
                    color: "#383886",
                    //   display: "inline",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={() => setShow(!show)}
                >
                  CLICK HERE TO HIDE.
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CapnolearningBrochure;
