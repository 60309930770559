import React, { Component, useState, useEffect, useRef } from 'react';
import PrivacyPDF from '../pdf/privacy-policy-capnobook.pdf'
 
const BookPrivacy = () => {
  
    return (
        <div className="wrp-dashbord ">
           <object data={PrivacyPDF} type="application/pdf" width="100%" height="750vh" />

        </div>
    )
}
export default BookPrivacy;