module.exports = {
   
    // API_URL : "http://localhost:3004/api",
    API_URL : "https://dbpatb2zpylw8.cloudfront.net/api",

    BOOK_API_URL: "https://dc61vll85tvge.cloudfront.net",
    // BOOK_API_URL : "http://localhost:3012",

    BOOK_LINK_W: "https://github.com/arsh09/capnoreader-releases/releases/download/v1.0.0/CapnoBook-Setup-1.0.0.exe",
    BOOK_LINK_M: "https://github.com/arsh09/capnoreader-releases/releases/download/v1.0.0/CapnoBook-1.0.0.dmg",
    BOOK_LINK_A: "#",
    BOOK_LINK_I: "#"
    
    // API_URL : "https://e-campus-api.herokuapp.com/apix",
 
    
    
}