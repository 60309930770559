import React, { Component, useState, useEffect, useRef } from 'react';
import windows from "../../images/os/windows.png";
import mac from "../../images/os/mac.png";
import {BOOK_API_URL} from '../../../Config/index'


const Invoice = () => {

    const transaction_id = localStorage.getItem('pdftxn')
    const email = localStorage.getItem('pdfemail');
    // const email = "yasirlhancse@"
    const [windowurl, setWindowurl] = useState([])
    const [macurl, setMacurl] = useState([])


    useEffect(() => {
      
        getUrl();



    }, [])

    const getUrl = () => {


        fetch(BOOK_API_URL+"/get/settings",
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',

                },
            }
        ).then((response) => {
            if (response.status == 200) {
                response.json().then((resp) => {
                    setWindowurl(resp.settings[0].pdf_url_windows);
                    setMacurl(resp.settings[0].pdf_url_mac);
                    
                    
                });
            }
            


        })
    }

    return (
        <div className="wrp-dashbord pt-3">

            <div className="container-fluid" >

            <div className='invoiceDiv'>
                            <p><b className='thank_b'>Thank you for the purchase!</b></p>
                            <div>
                                <p className='invoice_p'>Your transaction ID is: <b className='id_b'>{transaction_id}</b></p>
                                <p className='invoice_p'>We have also emailed you your transaction ID.</p>
                            </div>            <div className='instruction_div'> <b className='invoice_b'>Instructions:</b>
                                
                            </div>
                            <div className='wrp-voice-notes'><p className='invoice_ol'><span className='number_span'>(1)</span>  Click on an option below to download the book-access software.</p> <p className='invoice_ol'><span className='number_span'>(2)</span> Install the book-access software.</p> <p className='invoice_ol'><span className='number_span'>(3)</span> Enter your Transaction ID when prompted.</p> <p className='invoice_ol'><span className='number_span'>(4)</span> Click on the CapnoBook icon to begin reading.</p> 
                            <br />
                            <span className='smallerfont' ><b>Note:</b> You will not be asked for the Transaction ID again.</span>

                            </div>
                            <div className='invoice_img_content'>
                                <a href={macurl} className='invoice_macImageDiv' download>
                                    <img src={mac} alt="Mac image here" className='invoice_macImg' />
                                    <p className='invoice_macSpan'>Download for Mac</p>
                                </a>
                                <a href={windowurl} className='invoice_imageDiv' download>
                                    <img src={windows} alt="Mac image here" className='invoice_windowImg' />
                                    <p className='invoice_macSpan'>Download for Windows</p>
                                </a>
                            </div>
                         
                             <div className='last_div'> <p className='invoice_pp'>Please let us know what you think of the book. Write to <a className="hyperlink" href="mailto:education@bp.edu" >education@bp.edu</a>.</p> <br /><span className='invoice_ps'>Please remember:</span> <ul className='ul_invoice'>     <li className='invoice_ul'>Save your transaction id in case you need it for future downloads.</li>
                                <li className='invoice_ul'>You may download the Access-book code on three devices</li> </ul> </div>
                        </div>
            </div>
        </div>
    )
}
export default Invoice;